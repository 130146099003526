<template>
	<!-- 代理-侧栏 -->
	<div class="my-container">
		<el-row class="my-content">
			<el-col :span="4">
				<div class="user-header">
					<el-image class="head-img" :src="userInfo.companyLogo" v-if="userInfo.companyLogo"></el-image>
					<div class="name">{{companyInfo.companyName}}</div>
					<div class="tips">{{companyInfo.agentName}}</div>
				</div>
				<div class="menu-lists">
					<router-link v-for="(item,index) in menuList" :key="index" tag="span" :to="{name:item.name}" @click="onSlide(item)" class="item">
						{{item.label}}
					</router-link>
				</div>
			</el-col>
			<el-col :span="20" class="side-right">
				<router-view />
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {menuList} from '@/config/agency'
	export default {
		data() {
			return {
				menuList,
				companyInfo:{
					logo:require('@/assets/images/agencyImg.png')
				},				
			};
		},
		computed: {
			userInfo(){
				let userInfo=this.$store.state.userInfo;
				if(userInfo.companyLogo){
					return userInfo
				}else{
					return JSON.parse(localStorage.getItem('userInfo'));
				}
			}
		},
		created(){
			this.getCompanyInfo()
		},
		methods: {
			getCompanyInfo(){
				this.$http
				.get('/api/web/my/getMemberInfo')
				.then(res=>{
					this.companyInfo=res.data.companyInfo;	
					let userInfo=JSON.parse(localStorage.getItem('userInfo'));
					this.$store.commit('SET_USER_INFO',Object.assign(userInfo,{companyLogo:this.companyInfo.logo}))				
				}).catch(err=>{
					this.$toast(err.msg)
				})
			},
		}
	};
</script>

<style lang='less' scoped>
	.my-container {
		background: #f5f5f5;
		padding: 32px 0;
	}

	.my-content {
		width: 1300px;
		margin: 0 auto;

		.side-right {
			padding-left: 30px;
		}

		.menu-lists {
			display: flex;
			flex-direction: column;
			padding: 36px;
			background: #fff;

			.item {
				font-size: 16px;
				line-height: 75px;
				color: #666;
				cursor: pointer;
				&.router-link-active {
					color: #D71A18;
				}
			}
		}

		.user-header {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 25px;
			margin-bottom: 20px;
			background: #fff;

			.head-img {
				width: 90px;
				height: 90px;
				border-radius: 50%;
				margin-bottom: 14px;
			}

			.name {
				font-size: 16px;
				color: #333;
				margin-bottom: 15px;
			}

			.tips {
				font-size: 14px;
				color: #666;
			}
		}
				

	}
</style>
