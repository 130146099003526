<template>
	<div class="header">
		<div class="content">
			<div class="logo">
				<img src="@/assets/images/logo-head.png" class="head-logo" alt="IP规划师">
				<div class="link link1" @click="goYiji"></div>
				<div class="link link2" @click="onRoute('Home')"></div>
			</div>
			<div class="navs">
				<router-link v-for="(item,index) in list" :key="index" :to="{path:item.path}" class="item">
					{{item.label}}
					<div class="sub-nav" v-if="index==2">
						<router-link class="sub-item" v-for="(item,index) in item.subList" :key="'nav'+index" :to="{path:item.path}">{{item.label}}</router-link>
					</div>
				</router-link>
			</div>
			<div class="search">
				<el-input placeholder="初级IP规划师" v-model="searchVal" @keyup.enter.native="onEnterSearch">
					<i slot="suffix" class="el-input__icon el-icon-search" @click="onEnterSearch"></i>
				</el-input>
			</div>
			<div class="chat" @mouseover="codeActive=true" @mouseout="codeActive = false">
				<img src="@/assets/icons/icon-chat.png" class="icon-head" alt="">
				<div class="wx-code" v-show="codeActive">
					<img src="@/assets/images/wx.png" class="code" alt="">
					<div class="txt">微信扫一扫进入小程序</div>
				</div>
			</div>
			<div class="user" @mouseover="menuActive=true" @mouseout="menuActive = false">
				<img :src="userInfo.memberHead" class="icon-head-img"  v-if="hasToken">
				<img src="@/assets/icons/icon-user.png" class="icon-head" title="请登录" @click="onRoute('Login')" v-else>
				<div class="menu-box" v-show="menuActive" v-if="hasToken">
					<router-link v-for="(item,index) in menuList" :key="index" :to="{path:item.path}" class="item">
						{{item.label}}
					</router-link>
					<div class="item" @click="onLayout" v-if="hasToken">退出登录</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
import { menuList } from '@/config/menu';
import { removeToken,getToken } from '@/utils/auth';
export default {
  props: {},
  data() {
    return {
      menuList,
      codeActive:false,
      menuActive:false,
			hasToken:false,
      list: [
        {
          label: "首页",
          path: "/home"
        },{
          label: "课程",
          path: "/course",
        },{
          label: "资讯",
          path: "/news",
					subList:[{
						label:'资讯讯息',
						path: "/news/index",
					},{
						label:'考试安排',
						path: "/news/exam",
					},{
						label:'项目政策',
						path: "/news/project",
					},{
						label:'问题答疑',
						path: "/news/problem",
					}]
        },{
          label: "专家库",
          path: "/expert",
        },{
          label: "联合办学",
          path: "/institution",
        },{
          label: "实训基地",
          path: "/training",
        },{
          label: "模拟考试",
          path: "/answer",
        },{
          label: "成绩查询",
          path: "/results",
        }
      ],
      searchVal: "",
			member:{},
    };
  },
  created(){
		this.hasToken = getToken();
		// console.log(this.hasToken)
  },
	computed: {
		userInfo(){
			let userInfo=this.$store.state.userInfo;
			if(userInfo.memberHead){
				return userInfo
			}else{
				return JSON.parse(localStorage.getItem('userInfo'));
			}
		}
	},
  methods: {
    onEnterSearch() {
			let routeUrl = this.$router.resolve({
				name: "Search",
				query:{searchVal:this.searchVal}
			});
			window.open(routeUrl.href, '_blank');
    },
		onRoute(name){
			this.$router.push({name})
		},
		goYiji(){
			window.open('http://artuns.com/', '_blank');
		},
		onLayout() {
      this.$http
			.get('/api/web/logout')
			.then(res => {
				this.$toast(res.msg)
				setTimeout(()=>{
					removeToken();
					this.$store.commit("SET_USER_INFO", '');
					this.hasToken=false;
					this.$router.replace({ name: 'Home'});
				},1500)
      }).catch(err => {
				this.$message({
          message: err.msg || '退出失败',
          type: 'warning'
        });
      })
    },
  },
};
</script>
<style lang='less' scoped>
	.header {
		background: #fff;
		box-shadow: 0px 0px 9px 0px rgba(6, 0, 1, 0.3);
	}
	.content {
		width: 1300px;
		height: 60px;
		margin: 0 auto;
		display: flex;
		align-items: center;		
		.logo {
			margin-right: 50px;
      margin-left: -100px;
			@media (max-width: 1920PX){
				margin-right: 40px;
				margin-left: -100px;
			}
			@media (max-width: 1366PX){
				margin-right: 0px;
				margin-left: -80px;
			}
			@media (max-width: 1200PX){
				margin-right: 0px;
				margin-left: -50px;
			}
			position: relative;
			.link{
				position: absolute;
				z-index: 2;
				top: -10px;
				left: 0;
				width: 120px;
				height: 60px;
				cursor: pointer;
			}
			.link1{
				width: 100px;
			}
			.link2{
				left: 100px;
				width: 150px;
			}
			.head-logo {
				height: 38px;
			}
		}		
		.navs {
			min-width: 720px;
			display: flex;			
			.item {
				height: 60px;
				line-height: 60px;
				max-width: 110px;
				padding: 0 17px;
				@media (max-width: 1600PX){
					padding: 0 15px;
				}
				@media (max-width: 1366PX){
					padding: 0 12px;
				}
				@media (max-width: 1200PX){
					padding: 0 5px;
				}
				margin: 0 5px;
				font-size: 16px;
				color: #333;
				text-decoration: none;
				position: relative;	
				.sub-nav{
					display: none;
					border-radius: 0px 0px 4px 4px;
					box-shadow: 0px 0px 9px 0px rgba(5,0,0,0.3); 
				}		
				&:hover,&.router-link-active {
					color: #fff;
					background: #D91918;
				}
				
				&:hover {
					.sub-nav{
						background: #fff;
						display: block;
						width: 110px;
						line-height: 50px;
						position: absolute;
						z-index: 999;
						top: 60px;
						left: 0;
						.sub-item{
							display: inline-block;
							text-decoration: none;
							color: #333;
						}
						.sub-item:hover,.sub-item.router-link-active{
							color: #D71A18;
						}
					}
				}
			}
		}		
		/deep/ .search {
			// border: 1px solid #ccc;
			flex: 0 0 200px;
			margin-left: auto;
			margin-right: 10px;		
			.el-icon-search{
				cursor: pointer;
			}	
			.el-input__inner {
				border-radius: 40px;
			}
		}		
		.icon-head {
			height: 25px;
		}	
		.icon-head-img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
		}		
		.chat {
			display: flex;
			align-items: center;
			position: relative;
			height: 60px;
			padding: 0 10px;
			margin-left: 10px;
			cursor: pointer;			
			.wx-code {
				position: absolute;
				right: 0;
				top: 60px;
				z-index: 99;
				padding: 15px;
				background: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				box-shadow: 0px 0px 9px 0px rgba(6, 0, 1, 0.3);
				border-radius: 4px;
				.code{
					width: 120px;
					height: 120px;
				}
				.txt{
					margin: 6px 0;
					font-size: 12px;
					color: #333;
				}
			}		
		}			
		.user {
			display: flex;
			align-items: center;
			position: relative;
			height: 60px;
			padding: 0 10px;
			margin-left: 10px;
			cursor: pointer;			
			.menu-box {
				display: flex;
				flex-direction: column;
				position: absolute;
				right: 0;
				top: 60px;
				z-index: 99;
				width: 112px;
				height: 300px;
				background: #FFFFFF;
				box-shadow: 0px 0px 9px 0px rgba(6, 0, 1, 0.3);
				border-radius: 0px 0px 4px 4px;			
				.item {
					font-size: 16px;
					line-height: 50px;
					color: #666;
					text-decoration: none;	
					&:hover{
						color: #D71A18;
					}		
					&.router-link-active {
						color: #D71A18;
					}
				}
			}
		}
	}
</style>
