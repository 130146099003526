<template>
<div class="bg-container">
  <router-view />
</div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
.bg-container{
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
}
</style>