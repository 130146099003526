import agPersonSafe from '@/views/pc/layouts/school/safe.vue'
export default [{
		path: 'index',
		name: 'SchoolIndex',
		meta: { title: '日常数据'	},
		component: () => import( /* webpackChunkName: "schoolIndex" */'@/views/pc/school/index.vue')
	},{
		path: 'info',
		name: 'SchoolInfo',
		meta: { title: '学员信息'	},
		component: () => import( /* webpackChunkName: "schoolInfo" */ '@/views/pc/school/info.vue')
	},{
		path: 'student',
		name: 'SchoolStudent',
		meta: { title: '学员信息'	},
		component: () => import( /* webpackChunkName: "schoolStudent" */ '@/views/pc/school/student.vue')
	},
	{
		path: 'safe',
		name: 'SchoolSafe',
		meta: {title: '账号安全'},
		component: agPersonSafe,
		children: [		
		{
			path: 'bindModify',
			name: 'SchoolModify',
			meta: { title: '绑定手机号' },
			component: () => import( /* webpackChunkName: "agBindModify" */ '@/views/pc/my/modify.vue')
		},]
	}
]
