<template>
	<div id="app" class="app">
		<transition name="fade-transform" mode="out-in">
			<router-view />		
		</transition>
		<div class="fixed-icons" v-show="isActive">
			<div class="icon-service" @mouseover="imgActive=true" @mouseout="imgActive = false"></div>
			<div class="icon-signup" @click="$router.push({name:'CourseEnroll'})"></div>
			<div class="wx-code" v-show="imgActive">
				<img src="@/assets/images/wx-code.png" class="code" alt="">
				<div class="txt">微信扫一扫添加客服</div>
			</div>
		</div>
		<el-backtop target=".app" v-show="isActive"></el-backtop>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex'
	export default {
		data() {
			return {
				imgActive:false,
				isActive:false,
			}
		},
		mounted: function() {
			window.addEventListener('scroll', this.handleScroll, true); // 监听（绑定）滚轮滚动事件		
		},
		methods: {
			handleScroll(e) {
				const isMobile =navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
				const scrollTop = e.target.scrollTop	
				if (isMobile) {
					this.isActive = false
				} else {
					if (scrollTop > 120) { 
						this.isActive = true
					} else {
						this.isActive = false
					}
				}					
			}
		},
		destroyed: function() {
			window.removeEventListener('scroll', this.handleScroll,true); //  离开页面清除（移除）滚轮滚动事件
		}
	}
</script>

<style lang="less">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
	}
	.app{
		height: 100vh;
		overflow-x: hidden;
		.fixed-icons{
			display: block;
			position: fixed;
			right: 40px;
			bottom: 100px;
			width: 40px;
			height: 100px;
			
			.icon-service,.icon-signup{
				background-color: #fff;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				color: #d71a18;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
				box-shadow: 0 0 6px rgba(0,0,0,.12);
			
			}
			.icon-service{
				background-image: url(assets/icons/icon-service.png);
				background-position: center;
				background-repeat: no-repeat;
				margin-bottom: 20px;
				cursor: pointer;
			}
			.icon-signup{
				background-image: url(assets/icons/icon-signup.png);
				background-position: center;
				background-repeat: no-repeat;
			}
			.wx-code {
				position: absolute;
				right: 60px;
				top: 0;
				z-index: 99;
				padding: 15px;
				background: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				box-shadow: 0px 0px 9px 0px rgba(6, 0, 1, 0.3);
				border-radius: 4px;
				.code{
					width: 120px;
					height: 120px;
				}
				.txt{
					margin: 6px 0;
					font-size: 12px;
					color: #333;
				}
			}
		}	
		
	}
	html,
	body {
		margin: 0;
	}

	* {
		box-sizing: border-box;
	}
	
</style>
