// 解决ie白屏问题
import '@babel/polyfill'
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
Vue.use(Meta)
// import './setFontSize'

import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/theme/index.css'
Vue.use(ElementUI);

import './components/Toast/index.css';
import Toast from './components/Toast/index';
Vue.use(Toast);

import request from './utils/request'
import Plugins from './utils/plugins'

Vue.use(Plugins)

// 视频播放
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer);

import "swiper/swiper.less"
import './styles/index.less'
import './permission'

// import './assets/iconfont/iconfont.css'

//下拉刷新上拉加载
import VueLoadmore from 'vuejs-loadmore';
Vue.use(VueLoadmore);

Vue.prototype.$http = request
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
