import agPersonSafe from '@/views/pc/layouts/agency/safe.vue'
export default [{
		path: 'index',
		name: 'DailyData',
		meta: {
			title: '日常数据'
		},
		component: () => import( /* webpackChunkName: "dailyData" */ '@/views/pc/agency/index.vue')
	},
	{
		path: 'basicInfo',
		name: 'BasicInfo',
		meta: {
			title: '基础信息'
		},
		component: () => import( /* webpackChunkName: "basicInfo" */ '@/views/pc/agency/baseinfo.vue')
	},
	{
		path: 'studentInfo',
		name: 'StudentInfo',
		meta: {
			title: '学员信息'
		},
		component: () => import( /* webpackChunkName: "studentInfo" */ '@/views/pc/agency/student.vue')
	},
	{
		path: 'order',
		name: 'OrderManage',
		meta: {
			title: '订单管理'
		},
		component: () => import( /* webpackChunkName: "orderManage" */ '@/views/pc/agency/order.vue')
	},
	{
		path: 'safe',
		name: 'AgencySafe',
		meta: {title: '账号安全'},
		component: agPersonSafe,
		 children: [
		//{
		// 	path: 'PwdChange',
		// 	name: 'AgPwdChange',
		// 	meta: { title: '密码修改' },
		// 	component: () => import( /* webpackChunkName: "agPwdChange" */ '@/views/pc/my/safe.vue')
		// },
		{
			path: 'bindModify',
			name: 'AgBindModify',
			meta: { title: '绑定手机号' },
			component: () => import( /* webpackChunkName: "agBindModify" */ '@/views/pc/my/modify.vue')
		},]
	}
]
