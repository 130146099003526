import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import hasHeader from '@/views/pc/layouts/index.vue'
import personSide from '@/views/pc/layouts/person/index.vue'
import agencySide from '@/views/pc/layouts/agency/index.vue'
import schoolSide from '@/views/pc/layouts/school/index.vue'
import Empty from '@/views/pc/layouts/page.vue'
import h5Layout from '@/views/h5/layout.vue'


import User from './modules/user'
import Agency from './modules/agency'
import School from './modules/school'

const redirect = '/home',h5Redirect = '/h5/home';
const routes = [{
	path: '/h5',
	name: 'WapHeader',
	meta: { title: 'IP规划师培训官网' },
	component: h5Layout,
	h5Redirect,
	children: [{
		path: 'home',
		name: 'WapHome',
		component: () => import( /* webpackChunkName: "wapHome" */ '@/views/h5/home.vue')
	},
	{
		path: "course",		
		name: 'WapCourse',
		meta: {
			title: 'h5课程'
		},
		component: () => import( /* webpackChunkName: "wapCourse" */ '@/views/h5/course.vue')
	},	
	{
		path: "notice",		
		name: 'WapNotice',
		meta: {
			title: 'h5资讯'
		},
		component: () => import( /* webpackChunkName: "wapNotice" */ '@/views/h5/notice.vue')
	},
	{
		path: "information",		
		name: 'WapInformation',
		meta: {
			title: 'h5通知'
		},
		component: () => import( /* webpackChunkName: "wapInformation" */ '@/views/h5/information.vue')
	},
	{
		path: "details",		
		name: 'WapDetails',
		meta: {
			title: 'h5资讯详情'
		},
		component: () => import( /* webpackChunkName: "wapDetails" */ '@/views/h5/details.vue')
	},
	{
		path: "expert",		
		name: 'WapExpertList',
		meta: {
			title: 'h5专家库'
		},
		component: () => import( /* webpackChunkName: "wapTeacher" */ '@/views/h5/teachers/index.vue')
	},
	{
		path: "expertDetails",		
		name: 'WapExpertDetails',
		meta: {
			title: 'h5专家库详情'
		},
		component: () => import( /* webpackChunkName: "wapTeacherDetails" */ '@/views/h5/teachers/detail.vue')
	}
]},{
		path: '/',
		name: 'Header',
		meta: {
			title: 'IP规划师培训官网'
		},
		component: hasHeader,
		redirect,
		children: [{
				path: '/home',
				name: 'Home',
				meta: {
					title: '首页',
					content: {
						keywords: 'IP规划师培训官网',
						description: 'IP规划师培训官网'
					}
				},
				component: () => import( /* webpackChunkName: "home" */ '@/views/pc/home/index.vue')
			},						
			{
				path: '/search',
				name: 'Search',
				meta: {
					title: '搜索页'
				},
				component: () => import( /* webpackChunkName: "search" */ '@/views/pc/home/search.vue')
			},
			{
				path: '/agreement',
				name: 'Agreement',
				meta: {
					title: '用户协议及隐私政策',
					content: {
						keywords: 'IP规划师培训官网',
						description: 'IP规划师培训官网'
					}
				},
				component: () => import( /* webpackChunkName: "agreement" */ '@/views/pc/home/agreement.vue')
			},
			{
				path: '/about',
				name: 'About',
				meta: {
					title: '关于我们',
					content: {
						keywords: 'IP规划师培训官网,',
						description: 'IP规划师培训官网'
					}
				},
				component: () => import( /* webpackChunkName: "about" */ '@/views/pc/home/about.vue')
			},
			{
				path: '/help',
				name: 'Help',
				meta: {
					title: '帮助中心',
					content: {
						keywords: 'IP规划师培训,IP规划师培训帮助中心',
						description: 'IP规划师培训官网'
					}
				},
				component: () => import( /* webpackChunkName: "help" */ '@/views/pc/home/help.vue')
			},
			{
				path: '/news',
				name: 'News',
				component: Empty,
				redirect: '/news/exam',
				children: [{
						path: '',
						component: () => import('@/views/pc/notice/index.vue'),
						redirect: '/news/index',
						children: [{
								path: 'index',
								name: 'NewsList',
								meta: {
									title: '资讯讯息',
									content: {
										keywords: 'IP规划师培训,IP规划师培训资讯讯息',
										description: 'IP规划师培训官网'
									}
								},
								component: () => import( /* webpackChunkName: "newsList" */ '@/views/pc/news/index.vue'),
							},
							{
								path: 'exam',
								name: 'ExamArrangement',
								meta: {
									title: '考试安排'
								},
								component: () => import( /* webpackChunkName: "examArrangement" */ '@/views/pc/notice/exam-arrangement.vue')
							},
							{
								path: 'project',
								name: 'ProjectPolicy',
								meta: {
									title: '项目政策'
								},
								component: () => import( /* webpackChunkName: "projectPolicy" */ '@/views/pc/notice/project-policy.vue')
							},
							{
								path: 'problem',
								name: 'ProblemSolving',
								meta: {
									title: '问题解答'
								},
								component: () => import( /* webpackChunkName: "problemSolving" */ '@/views/pc/notice/problem-solving.vue')
							}
						]
					},
					{
						path: 'detail',
						name: 'NewsDetail',
						meta: {
							title: '资讯详情',
							content: {
								keywords: 'IP规划师培训,IP规划师培训资讯详情',
								description: 'IP规划师培训官网'
							}
						},
						component: () => import( /* webpackChunkName: "newsDetail" */ '@/views/pc/news/detail.vue'),
					},
				]
			},
			{
				path: '/expert',
				component: Empty,
				redirect: '/expert/index',
				children: [{
					path: 'index',
					name: 'ExpertList',
					meta: {
						title: '专家库'
					},
					component: () => import( /* webpackChunkName: "expert" */ '@/views/pc/teachers/index.vue'),
				}, {
					path: 'detail',
					name: 'ExpertDetail',
					meta: {
						title: '专家库'
					},
					component: () => import( /* webpackChunkName: "expertDetail" */ '@/views/pc/teachers/detail.vue'),
				}]
			},
			{
				path: '/course',
				name: 'Course',
				component: Empty,
				redirect: '/course/index',
				children: [{
					path: 'index',
					name: 'CourseIndex',
					meta: {
						title: '课程报名',
						content: {
							keywords: 'IP规划师培训,IP规划师培训资讯详情',
							description: 'IP规划师培训官网'
						}
					},
					component: () => import( /* webpackChunkName: "courseIndex" */ '@/views/pc/course/index.vue'),
				}, {
					path: 'enroll',
					name: 'CourseEnroll',
					meta: {
						title: '课程报名'
					},
					component: () => import( /* webpackChunkName: "courseEnroll" */ '@/views/pc/course/enroll.vue'),
				}, {
					path: 'play',
					name: 'CoursePlay',
					meta: {
						title: '课程播放'
					},
					component: () => import( /* webpackChunkName: "coursePlay" */ '@/views/pc/course/play.vue'),
				}]
			},
			{
				path: '/training',
				component: Empty,
				redirect: '/training/index',
				children: [{
					path: 'index',
					name: 'TrainingBase',
					meta: {
						title: '实训基地',
						content: {
							keywords: 'IP规划师培训,IP规划师培训资讯详情',
							description: 'IP规划师培训官网'
						}
					},
					component: () => import( /* webpackChunkName: "trainingBase" */ '@/views/pc/train/index.vue'),
				}, {
					path: 'detail',
					name: 'TrainingDetail',
					meta: {
						title: '基地详情',
						content: {
							keywords: 'IP规划师培训,IP规划师培训基地详情',
							description: 'IP规划师培训官网'
						}
					},
					component: () => import( /* webpackChunkName: "trainingDetail" */ '@/views/pc/train/detail.vue'),
				}, ]
			},
			{
				path: '/institution',
				component: Empty,
				redirect: '/institution/index',
				children: [{
					path: '',
					name: 'RunSchool',
					meta: {
						title: '联合办学',
						content: {
							keywords: 'IP规划师培训,IP规划师培训基地详情',
							description: 'IP规划师培训官网'
						}
					},
					component: () => import( /* webpackChunkName: "runSchool" */ '@/views/pc/train/school.vue'),
				}, {
					path: 'detail',
					name: 'SchoolDetail',
					meta: {
						title: '联合办学详情'
					},
					component: () => import( /* webpackChunkName: "schoolDetail" */ '@/views/pc/train/detail.vue'),
				}, ]
			},
			{
				path: '/results',
				component: Empty,
				redirect: '/results',
				children: [{
					path: '',
					name: 'ResultsQuery',
					meta: {
						title: '成绩查询',
						content: {
							keywords: 'IP规划师培训,IP规划师培训成绩查询',
							description: 'IP规划师培训官网'
						}
					},
					component: () => import( /* webpackChunkName: "resultsQuery" */ '@/views/pc/results/index.vue'),
				}, {
					path: 'enroll',
					name: 'ResultsEnroll',
					meta: {
						title: '证书申请'
					},
					component: () => import( /* webpackChunkName: "resultsEnroll" */ '@/views/pc/results/certificate.vue'),
				}, ]
			},
			{
				path: '/answer',
				component: Empty,
				redirect: '/answer/index',
				children: [{
					path: 'index',
					name: 'PracticeTest',
					meta: {
						title: '模拟考试',
						content: {
							keywords: 'IP规划师培训,IP规划师培训模拟考试',
							description: 'IP规划师培训'
						}
					},
					component: () => import( /* webpackChunkName: "PracticeTest" */ '@/views/pc/answer/index.vue'),
				}, {
					path: 'single',
					name: 'SingleTest',
					meta: {
						title: '单项选择'
					},
					component: () => import( /* webpackChunkName: "singleTest" */ '@/views/pc/answer/single.vue'),
				}]
			},
			{
				path: '/my',
				redirect: '/my/index',
				component: personSide,
				children: User
			},
			{
				path: '/agency',
				redirect: '/agency/index',
				component: agencySide,
				children: Agency
			},
			{
				path: '/school',
				redirect: '/school/index',
				component: schoolSide,
				children: School
			},
		]
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: '登录'
		},
		component: () => import( /* webpackChunkName: "login" */ '@/views/pc/login/login.vue')
	},
	{
		path: '/register',
		name: 'Register',
		meta: {
			title: '注册'
		},
		component: () => import( /* webpackChunkName: "register" */ '@/views/pc/login/register.vue')
	},
	{
		path: '/forgetPwd',
		name: 'ForgetPwd',
		meta: {
			title: '忘记密码'
		},
		component: () => import( /* webpackChunkName: "forgetPwd" */ '@/views/pc/login/forgetPwd.vue')
	},
	{
		path: '/404',
		name: 'Notfound',
		component: () => import( /* webpackChunkName: "notfound" */ '@/views/pc/common/notfound.vue')
	},
	{
		path: '*',
		redirect: '/404'
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router