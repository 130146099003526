<template>
  <div class="layout-container">
    <div class="header">
      <img
        src="@/assets/images/logo-head.png"
        class="head-logo"
        alt="IP规划师"
      />
      <div class="menu" @click=" drawer = true">
        <img class="icon-menu" src="@/assets/wap/icon-menu.png"/>
      </div>
    </div>
    <router-view>

    </router-view>
    <div class="footer">
      <div class="wx-box">
        <img class="wx-code" src="@/assets/wap/wx.png" alt="" />
        <div class="txt">更多信息请前往IP规划师小程序查看</div>
      </div>
      <div class="company-info">
        <div class="company-txt">官方网站：https://www.ipuol.cn</div>
        <div class="company-txt">合作热线：400 633 8088 (9:00-18:00)</div>
        <div class="company-txt">举报邮箱：artuns@163.com (9:00-18:00)</div>
      </div>
    </div>
    <el-drawer
      class="menu-drawer"
      :visible.sync="drawer"
      :direction="direction">
      <div class="drawer-content">
        <div class="item" @click="onRoute('/h5/home')">首页</div>
        <div class="item" @click="onRoute('/h5/notice')">资讯</div>
        <div class="item" @click="onRoute('/h5/expert')">专家库</div>
      </div>
    </el-drawer>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      drawer: false,
      direction: 'rtl',
    };
  },  
  methods: {
    onRoute(url){
      this.$router.push({path:url});
      this.drawer = false
    }
  },
};
</script>

<style lang='less' scoped>
.layout-container{
  background: #f8f8f8;
  .container{
    min-height: calc(100vh - 7.24rem);
  }
  .header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .23rem .26rem;
    background: #fff;
    z-index: 2;
    .head-logo{
      width: 4.9rem;
    }
    .icon-menu{
      width: .45rem;
    }
  }
  /deep/.menu-drawer{
    .el-drawer {
      width: 60% !important;
      background: rgba(0, 0, 0 ,.7);
      .el-dialog__close{
        font-size: .6rem;
        color: #f3f3f3;
      }
      .drawer-content{
        .item{
          font-size: .30rem;
          color: #fff;
          margin:0 .8rem;
          line-height: .9rem;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
  .footer{
    display: flex;
    flex-direction: column;
    background: #333;
    color: #fff;
    .wx-box{
      padding: .6rem .26rem .34rem;
      border-bottom: 1px solid #474747;
      .wx-code{
        width:2.5rem;
        margin-bottom: .3rem;
      }
      .txt{
        font-size: .24rem;
      }
    }
    .company-info{
      padding: .15rem .2rem;
      .company-txt{
        font-size: .24rem;
        color: #ccc;
        line-height: .6rem;
      }
    }
  }
}
</style>
