import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// const whiteList = ['/','/agency','/agreement','/login',,'/course/index','/home','/courseEnroll','/news/index','/institution','/answer','/results','/about','/help'] // no redirect whitelist
const whiteList = ['Home','Search','CourseIndex','Agreement','About','Help','News','NewsList','ExamArrangement','ProjectPolicy','ProblemSolving','NewsDetail','Course'
,'TrainingBase','TrainingDetail','RunSchool','SchoolDetail','ResultsQuery','Login','Register','ExpertList','ExpertDetail','WapHome','WapNotice','WapDetails','WapCourse','WapInformation','WapExpertList','WapExpertDetails'] // no redirect whitelist

const wrapList=['Home']
const isMobile =navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start() 
  const { meta = {} } = to
  if (!meta.doNotSetTitle && meta.title) {
    document.title = meta.title
  }
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    if(document.querySelector('meta[name="keywords"]')){
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
      meta.content = to.meta.content;
      head[0].appendChild(meta)
    }
  }
  next()
  NProgress.done()
  const breadcrumbList=to.matched.map(item=>{
    return{
      name:item.meta.title?item.meta.title:'',
      path:item.path?item.path:'/'
    }
  })
  if(isMobile){
    if(wrapList.indexOf(to.name) !== -1){
      next({ path: `/h5${to.fullPath}` });
    }else{
      if(to.fullPath.indexOf('h5') == -1){
        next({ path: `/h5/home` });
      }
      
    }
    
    // console.log(to.fullPath)
    // next({ path: `/h5${to.fullPath}` });
    // NProgress.done();
    // return false;
  }else{
    store.commit("SET_CRUMB_LIST", breadcrumbList);
    const hasToken = getToken()
    if (hasToken) {
      
      if (to.path === '/login') {
        // if is logged in, redirect to the home page
        next({ path: '/' })
        NProgress.done()
      } else {
        next()
      }
    } else {
      // console.log(to.name)
      
      if (whiteList.indexOf(to.name) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
  
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
