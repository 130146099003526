<template>
	<div class="my-container">
		<el-row class="my-content">
			<el-col :span="4">
				<div class="user-header">
					<el-image class="head-img" :src="userInfo.memberHead" v-if="userInfo.memberHead"></el-image>
					<div class="name" v-if="userInfo.nickname">{{userInfo.nickname}}</div>
					<!-- <div class="tips">已报名初级课程</div> -->
				</div>
				<div class="menu-lists">
					<router-link v-for="(item,index) in menuList" :key="index" tag="span" :to="{ path:item.path }" class="item">
						{{item.label}}
					</router-link>
				</div>
				<div class="menu-lists menu-footer" v-if="!!member.companyType">
					<div class="item" v-if="member.companyType == 1" @click="onRoute('DailyData')">代理端</div>
					<div class="item" v-else-if="member.companyType == 2" @click="onRoute('SchoolIndex')">学校端</div>
				</div>
			</el-col>
			<el-col :span="20" class="side-right">
				<router-view />
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { menuList } from '@/config/menu'
	export default {
		data() {
			return {
				menuList,
				member:{
					headImg:require('@/assets/images/ip_default_head.jpg')       
				},
			};
		},
		computed: {
			userInfo(){
				let userInfo=this.$store.state.userInfo;
				if(userInfo.memberHead){
					return userInfo
				}else{
					return JSON.parse(localStorage.getItem('userInfo'));
				}
			}
		},
		created(){
			this.getMyUserInfo()
		},
		methods: {
			getMyUserInfo(){
				this.$http
				.get('/api/web/my/getMyUserInfo')
				.then(res=>{
					this.member=res.data.member;
					let userInfo=JSON.parse(localStorage.getItem('userInfo'));
					this.$store.commit('SET_USER_INFO',Object.assign(userInfo,{memberHead:this.member.headImg}))	
				})
			},
			onRoute(name,type) {
				let routeUrl = this.$router.resolve({
					name,
					query:{type}
				});
				window.open(routeUrl.href, '_blank');
			},
		}
	};
</script>

<style lang='less' scoped>
	.my-container {
		background: #f5f5f5;
		padding: 32px 0;
	}

	.my-content {
		width: 1300px;
		margin: 0 auto;

		.side-right {
			padding-left: 30px;
		}

		.menu-lists {
			display: flex;
			flex-direction: column;
			padding: 36px;
			background: #fff;
			margin-bottom: 20px;
			.item {
				font-size: 16px;
				line-height: 75px;
				color: #666;
				cursor: pointer;
				&.router-link-active {
					color: #D71A18;
				}
			}
		}
		.menu-footer{
			padding: 10px 20px;
		}
		.user-header {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 25px;
			margin-bottom: 20px;
			background: #fff;

			.head-img {
				width: 90px;
				height: 90px;
				border-radius: 50%;
				margin-bottom: 14px;
			}

			.name {
				font-size: 16px;
				color: #333;
				margin-bottom: 15px;
			}

			.tips {
				font-size: 14px;
				color: #666;
			}
		}

	}
</style>
