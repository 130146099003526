import Vue from 'vue'
import Vuex from 'vuex'
import request from '@/utils/request'
import { setToken } from '@/utils/auth';

Vue.use(Vuex)

import config from '@/config'

export default new Vuex.Store({
  state: {
    loading: false,
    userInfo: {
      memberHead:'',
    },
    breadcrumbList :[]
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_STATE_KEY(state, { key, data }){
      state[key] = data
    },
    SET_USER_INFO(state, data){
      state.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
    },
    SET_CRUMB_LIST(state, data){
      state.breadcrumbList = data
    },
  },
  getters: {
    loading: state => state.loading, 
    userInfo: state => state.userInfo,
    breadcrumbList:state => state.breadcrumbList,
  },
  actions: {
    // GetShopInfo({ commit }, data) {
    //   request.post('/api/user/getShopInfo').then(res => {
    //     const data = res.data
    //     commit('SET_STATE_KEY', { key: 'shopInfo', data: { ...data, requested: true } })
    //   })
    // },
    GetUploadToken() {
      return request.get('/api/web/common/getQiniuToken')
    },
    UploadToQiNiu({ commit }, data) {
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('token', data.qntoken)
      const uploadConfig = {
        headers:{'Content-Type':'multipart/form-data'}
      }
      return new Promise((resolve, reject) => {
        request.post(config.qnuploadurl, formData, uploadConfig).catch(err => {
          resolve(err)
        })
      })
    },
  },
  modules: {
  }
})
