
<template>
  <div class="exam-container">
    <div class="exam-head">
      <router-link tag="span" :to="{name:'ExamAppoint'}" class="item">
        考试预约
      </router-link>
      <router-link tag="span" :to="{name:'ExamList'}" class="item">
        我的预约
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { menuList } from '@/config/menu'
export default {
  data() {
    return {
      menuList,
    };
  },
};
</script>

<style lang='less' scoped>
.exam-container{
  background: #f5f5f5;
  .exam-head{
    display: flex;
    align-items: center;
    height: 68px;
    padding: 0 40px;
    margin-bottom: 1px;
    background: #fff;
    .item{
      margin-right: 30px;
      line-height: 66px;
      color: #666;
      cursor: pointer;
      &.router-link-active{
        color: #333;
        border-bottom: 2px solid #D71A18;
      }
    }
  }
}
</style>
