import axios from 'axios';
import router from '@/router'
// console.log(process.env)
export const baseURL = process.env.VUE_APP_API_URL
import { getToken, removeToken } from './auth'
/**
 * 异常处理程序
 */

/**
 * 配置request请求时的默认参数
 */
const request = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
  timeout: 25 * 1000, // 请求超时时间
});

request.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
);


/**
 * 对于状态码实际是 200 的错误
 */
request.interceptors.response.use(
  response => {
    const { data } = response
    const { status, msg } = data
    if (status !== 0) {
      if ([1001, 1002, 30000, 40000].includes(status)) { // 登录失效
        removeToken()
        router.push({name: 'Login'})
      }else{
        
      }
      
      return Promise.reject(data)
    }
    return data
  },
  error => {
    return Promise.reject(error)
  },
);

export default request;
