const AuthToken = 'token'

// 登录的token
export function getToken() {
  return localStorage.getItem(AuthToken)
}
export function setToken(token) {
  return localStorage.setItem(AuthToken, token)
}
export function removeToken() {
  return localStorage.removeItem(AuthToken)
}