import personExam from '@/views/pc/layouts/person/exam.vue'
import personSafe from '@/views/pc/layouts/person/safe.vue'
export default [
  {
    path: 'index',
    name: 'PersonalMaterial',
    meta: { title: '个人资料' },
    component: () => import(/* webpackChunkName: "personalMaterial" */ '@/views/pc/my/index.vue')
  },
  {
    path: 'course',
    name: 'PersonalCourse',
    meta: { title: '我的课程' },
    component: () => import(/* webpackChunkName: "personalCourse" */ '@/views/pc/my/course.vue')
  },
  {
    path: 'exam',
    name: 'PersonalExam',
    component: personExam,
    redirect: '/my/exam/appoint',
    children: [{
      path: 'appoint',
      name: 'ExamAppoint',
      meta: { title: '考试预约' },
      component: () => import( /* webpackChunkName: "examAppoint" */'@/views/pc/my/appoint.vue')
    },{
      path: 'list',
      name: 'ExamList',
      meta: { title: '我的预约' },
      component: () => import( /* webpackChunkName: "examList" */'@/views/pc/my/exam.vue')
    },]
  },
  {
    path: 'order',
    name: 'PersonalOrder',
    meta: { title: '我的订单' },
    component: () => import( /* webpackChunkName: "personalOrder" */ '@/views/pc/my/order.vue')
  },
  {
    path: 'safe',
    name: 'PersonalSafe',
    meta: { title: '账号安全' },
    component: personSafe,
	children: [
  //   {
	//   path: 'pwdChange',
	//   name: 'PwdChange',
	//   meta: { title: '密码修改' },
	//   component: () => import( /* webpackChunkName: "PersonalPwdChange" */'@/views/pc/my/safe.vue')
	// }
  ,{
	  path: 'bindModify',
	  name: 'BindModify',
	  meta: { title: '绑定手机号' },
	  component: () => import( /* webpackChunkName: "PersonalBindModify" */'@/views/pc/my/modify.vue')
	},]
  },
  {
    path: 'signOut',
    name: 'PersonalSignOut',
    meta: { title: '退出登录' },
    component: () => import( /* webpackChunkName: "PersonalSignOut" */ '@/views/pc/my/signOut.vue')
  },
]