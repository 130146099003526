<template>
	<div class="my-container">
		<el-row class="my-content">
			<el-col :span="4">
				<div class="user-header">
					<el-image class="head-img" :src="userInfo.logo" v-if="userInfo.logo">
					</el-image>
					<div class="name">{{userInfo.companyName}}</div>
					<div class="tips" v-if="userInfo.type==1">{{userInfo.agentName}}</div>
				</div>
				<div class="menu-lists">
					<router-link v-for="(item,index) in schoolMenuList" :key="index" tag="span" :to="{name:item.name}" class="item">
						{{item.label}}
					</router-link>
				</div>
			</el-col>
			<el-col :span="20" class="side-right">
				<router-view />
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				schoolMenuList:[{
					label:'日常数据',
					name:'SchoolIndex'
				},{
					label:'基础信息',
					name:'SchoolInfo'
				},
				{
					label:'学员信息',
					name:'SchoolStudent'
				},{
					label:'账号安全',
					name:'SchoolSafe'
				}],
				companyInfo:{
					logo:require('@/assets/images/schoolImg.png')
				},				
			};
		},
		computed: {
			userInfo(){
				let userInfo=this.$store.state.userInfo;
				if(userInfo.logo){
					return userInfo
				}else{
					return JSON.parse(localStorage.getItem('userInfo'));
				}
			}
		},
		created(){
			this.getCompanyInfo()
		},
		methods: {
			getCompanyInfo(){
				this.$http
				.get('/api/web/my/getMemberInfo')
				.then(res=>{
					this.companyInfo=res.data.companyInfo;
					let userInfo=JSON.parse(localStorage.getItem('userInfo'));
					this.$store.commit('SET_USER_INFO',Object.assign(userInfo,{
						logo:this.companyInfo.logo,
						companyName:this.form.companyName
						}))	
				})
			},
		}
	};
</script>

<style lang='less' scoped>
	.my-container {
		background: #f5f5f5;
		padding: 32px 0;
	}

	.my-content {
		width: 1300px;
		margin: 0 auto;

		.side-right {
			padding-left: 30px;
		}

		.menu-lists {
			display: flex;
			flex-direction: column;
			padding: 36px;
			background: #fff;

			.item {
				font-size: 16px;
				line-height: 75px;
				color: #666;
				cursor: pointer;
				&.router-link-active {
					color: #D71A18;
				}
			}
		}

		.user-header {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 25px;
			margin-bottom: 20px;
			background: #fff;

			.head-img {
				width: 90px;
				height: 90px;
				border-radius: 50%;
				margin-bottom: 14px;
			}

			.name {
				font-size: 16px;
				color: #333;
				margin-bottom: 15px;
			}

			.tips {
				font-size: 14px;
				color: #666;
			}
		}
				

	}
</style>
