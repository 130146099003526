export const menuList = [
  {
    label:'个人资料',
    path:'/my/index'
  },{
    label:'我的课程',
    path:'/my/course'
  },{
    label:'预约考试',
    path:'/my/exam'
  },{
    label:'我的订单',
    path:'/my/order'
  },{
    label:'账号安全',
    path:'/my/safe'
  }
]