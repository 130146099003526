
<template>
	<div class="exam-container">
		<div class="exam-head">
			<!-- <router-link tag="span" :to="{name:'AgPwdChange'}" class="item">
				密码修改
			</router-link> -->
			<router-link tag="span" :to="{name:'SchoolModify'}" class="item">
				绑定手机号
			</router-link>					
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import {menuList} from '@/config/menu'
	export default {
		data() {
			return {
				menuList,
			};
		},
		watch: {
			'$route'(to, from) {
				// console.log(to)
			}
		},
		created() {
			// console.log(this.$route.path == '/agency/orderManage')
			if (this.$route.path == '/school/safe') {
				this.$router.push({
					name: 'SchoolModify',
				})
			}
		},
		methods: {
			changeMenu(index) {
				this.active=index;
			},
			
		}
	};
</script>

<style lang='less' scoped>
	.exam-container {
		background: #f5f5f5;

		.exam-head {
			position: relative;
			display: flex;
			align-items: center;
			height: 68px;
			padding: 0 40px;
			margin-bottom: 1px;
			background: #fff;

			.item {
				margin-right: 30px;
				line-height: 66px;
				color: #666;

				&.router-link-active {
					color: #333;
					border-bottom: 2px solid #D71A18;
				}
			}
			
		}
	}
</style>
