<!--  -->
<template>
  <div class="footer">
    <div class="footinfo">
      <div class="content">
        <div class="ft-infos">
          <div class="ft-logo">
            <img
              src="@/assets/images/logo-footer.png"
              class="ft-logoimg"
              title="IP规划师"
            />
          </div>

          <p>合作热线：400 633 8088 <span>(9:00-18:00)</span></p>
          <p>举报邮箱：artuns@163.com <span>(9:00-18:00)</span></p>
        </div>

        <div class="ft-links">
          <div class="list">
            <div class="title">关于IP规划师</div>
            <div class="linkbox">
              <div class="item" @click="onRoute('Agreement','YHXY')">用户协议</div>
              <div class="item" @click="onRoute('About','LXWM')">联系我们</div>
              <div class="item" @click="onRoute('Agreement','YSZC')">隐私政策</div>
              <div class="item" @click="onRoute('Agreement','FWXY')">服务协议</div>
              <div class="item" @click="onRoute('Agreement','CQBH')">知识产权保护协议</div>
            </div>
          </div>
          <div class="list">
            <div class="title">帮助中心</div>
            <div class="linkbox">
              <div v-for="(item, index) in menu" :key="index" class="item" @click="onRoute('Help',item.type)">{{item.name}}</div>              
            </div>
          </div>
        </div>
        <div class="rt-contact">
          <div class="saoma-box">
            <div class="text">扫一扫关注二维码， 更多资讯活动等你哦！</div>
            <div class="img">
              <img src="@/assets/images/wx.png" />
              <span>小程序</span>
            </div>
            <div class="img">
              <img src="@/assets/images/service.png" />
              <span>服务号</span>
            </div>
          </div>
          <div class="contact-links">
            <div class="share-box">
              <div class="share-img" @click="shares('sina')"></div>
              <div class="share-img" @click="shares('qzone')"></div>
              <div class="share-img"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banquan">
      <div class="content">
        <p>
          <span
            >Copyright © 2021
            <el-link
              href="http://www.yoncc.com"
              target="_blank"
              :underline="false"
              >艺集网</el-link
            ></span
          >
          <span
            ><el-link
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502005495"
              target="_blank"
              :underline="false"
              >浙公网安备 33010502005495号</el-link
            ></span
          >
          <span
            >备案号:<el-link
              href="http://beian.miit.gov.cn"
              target="_blank"
              :underline="false"
              >浙ICP备17035820号-4</el-link
            ></span
          >
          <span
            ><el-link
              href="http://www.artuns.com/copyright/index?id=fj4lX"
              target="_blank"
              :underline="false"
              >高校毕业设计协同创新中心</el-link
            ></span
          >
          <span> 经营许可证：浙B2—20200904</span>
        </p>
        <p>
          <!-- <span
            ><el-link
              href="https://www.cnzz.com/stat/website.php?web_id=1277469520"
              target="_blank"
              :underline="false"
              title="站长统计"
              >站长统计</el-link
            ></span
          > -->
          <span
            ><el-link
              href="http://www.12377.cn/"
              target="_blank"
              :underline="false"
              title="站长统计"
              >网上有害信息举报专区</el-link
            ></span
          >
          <span>不良信息举报电话：0571-87653523</span>
        </p>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      menu: [{
        type:'ZCDL',
        name:"注册登录"
      },{
        type:'GKXX',
        name:"观看学习"
      },{
        type:'GMZF',
        name:"购买支付"
      },{
        type:'YYKS',
        name:"预约考试"
      },{
        type:'KSGZ',
        name:"考试规则"
      }],
      img:'',
      dec:'IP规划师',
      title:'IP规划师'
    };
  },
  components: {},
  methods: {
    shares(type) {
      //qq空间分享接口
      if (type == "qzone") {
        window.open(
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
            "https://gitee.com/overtrue/share.js/issues/IGIXK" +
            "&sharesource=" +
            "qzone" +
            "&pics=" +
            this.img +
            "&summary=" +
            this.dec +
            "&title=" +
            this.title
        );
      }
      //新浪微博接口的传参
      if (type == "sina") {
        window.open(
          "http://service.weibo.com/share/share.php?url=" +
            "https://gitee.com/overtrue/share.js/issues/IGIXK" +
            "&sharesource=" +
            "qzone" +
            "&pics=" +
            this.img +
            "&summary=" +
            this.dec +
            "&title=" +
            this.title
        );
      }
      //微信分享
      if (type == "wexin") {
      }
    },
    onRoute(name,type) {
      let routeUrl = this.$router.resolve({
        name,
        query:{type}
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>

<style lang='less' scoped>
.footer {
  .banquan {
    width: 100%;
    background-color: #171717;
    padding: 10px 0;
    .content {
      width: 1300px;
      height: auto;
      margin: 0 auto;
      P {
        font-size: 12px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        margin: 5px 0;
        span{margin: 0 10px;}
        .el-link {
          font-size: 12px;
          line-height: 24px;
          color: #fff;
        }
      }
    }
  }

  .footinfo {
    width: 100%;
    background: #333333;
    color: #fff;
    padding: 35px 0 30px;
    .content {
      display: flex;
      width: 1300px;
      height: auto;
      margin: 0 auto;
      .ft-infos {
        text-align: left;

        .ft-logo {
          width: 205px;
          margin-bottom: 65px;
        }
        p {
          font-size: 14px;
          margin-top: 25px;
          margin-bottom: 0;
          color: #dddddd;
        }
      }
      .ft-links {
        display: flex;
        margin-left: 90px;
        width: 500px;
        .list {
          &:first-child {
            margin-right: 60px;
          }
          .title {
            font-size: 16px;
            color: #fff;
            margin-bottom: 25px;
            text-align: left;
          }
          .linkbox {
            width: 240px;
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 50%;
              color: #dadada;
              text-align: left;
              line-height: 46px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .rt-contact {
        margin-left: auto;
        .saoma-box {
          display: flex;
          position: relative;
          width: 365px;
          height: 130px;
          background-color: #fff;
          margin-right: 15px;
          padding: 10px;
          .text {
            width: 150px;
            font-size: 13px;
            line-height: 24px;
            color: #454545;
            padding: 10px;
          }
          .img {
            width: 90px;
            font-size: 12px;
            text-align: center;
            margin-left: 5px;
            img{
              width: 100%;
            }
            span {
              text-align: center;
              color: #000;
            }
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -20px;
            right: 0;
            width: 0;
            border-top: 25px solid #fff;
            border-left: 25px solid transparent;
          }
        }
        .contact-links {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
          .share-box {
            display: flex;
            margin-left: auto;
            .share-img {
              width: 36px;
              height: 36px;
              margin: 0 40px 5px 0;
              background-image: url("~@/assets/images/contact-link.png");
              background-size:100% auto;
              background-repeat: no-repeat;
              cursor: pointer;
              background-color: #fff;
              border-radius: 50%;
              &:first-child {
                background-position: 0 -36px;
              }
              &:nth-child(2) {
                background-position: 0 -72px;
              }
              &:last-child {
                cursor: default;
                margin-right: 0;
                background-position: 0 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
