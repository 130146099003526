const NODE_ENV = process.env.NODE_ENV

const envOptions = {
  development: {
    domain: 'http://localhost:8080',
  },
  test: {
    domain: 'https://ipdevapi.artuns.com',
  },
  production: {
    domain: 'https://ipdevapi.artuns.com',
  },
}

export default {
  qnurl: 'http://ipqncdn1.artuns.com/',
  qnuploadurl: 'https://up-z0.qiniup.com',
  ...envOptions[NODE_ENV]
}