export const menuList = [
  {
    label:'日常数据',
    name:'DailyData'
  },{
    label:'基础信息',
    name:'BasicInfo'
  },{
    label:'学员信息',
    name:'StudentInfo'
  },{
    label:'订单管理',
    name:'OrderManage'
  },{
    label:'账号安全',
    name:'AgencySafe'
  },
]